.dashboardAddedHeight{
    height: 176px;
}
.addedHeight{
    /* height: 700px; */
}

.selectClass{
    padding: 12px;
    border-radius: 10px;
    border:2px solid #65676b;
}

.next-arrow{
    transform: rotate(180deg); 
}

/*----------------------------------------------
Background Color Opacity
------------------------------------------------*/
.iq-bg-primary {
    background: #ceebee !important;
    color: #089bab !important;
}

.iq-bg-secondary {
    background: #f1f2f1 !important;
    color: #777D74 !important;
}

.iq-bg-success {
    background: #e5fae5 !important;
    color: #00ca00 !important;
}

.iq-bg-danger {
    background: #fcecec !important;
    color: #e64141 !important;
}

.iq-bg-warning {
    background: #fae9dd !important;
    color: #ffb177 !important;
}

.iq-bg-info {
    background: #e5faff !important;
    color: #00d0ff !important;
}

.iq-bg-dark {
    background: #ebecec !important;
    color: #374948 !important;
}

/*--------------*/
.iq-bg-primary-hover:hover {
    background: #e0f2f4 !important;
}

.iq-bg-primary-secondary-hover:hover {
    background: #f1f2f1 !important;
}

.iq-bg-primary-success-hover:hover {
    background: #eefff2 !important;
}

.iq-bg-primary-danger-hover:hover {
    background: #fff5f4 !important;
}

.iq-bg-primary-warning-hover:hover {
    background: #fffcf3 !important;
}

.iq-bg-primary-info-hover:hover {
    background: #e5fafc !important;
}

.iq-bg-primary-dark-hover:hover {
    background: #f0efef !important;
}

/*----------------------------------------------
Text Color
------------------------------------------------*/
.text-primary {
    color: #089bab !important;
}

.text-secondary {
    color: #a09e9e !important;
}

.text-success {
    color: #27b345 !important;
}

.text-danger {
    color: #f14336 !important;
}

.text-warning {
    color: #fbc647 !important;
}

.text-info {
    color: #6ce6f4 !important;
}

.text-light {
    color: #E9EDF4 !important;
}

.text-dark {
    color: #3f414d !important;
}

.text-white {
    color: #ffffff !important;
}

/*----------------------------------------------
Line Height
------------------------------------------------*/
.line-height {
    line-height: normal;
}

.line-height-2 {
    line-height: 2;
}

.line-height-4 {
    line-height: 40px;
}

.line-height-6 {
    line-height: 60px;
}

/*----------------------------------------------
Font Size
------------------------------------------------*/
.font-size-12 {
    font-size: 12px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-32 {
    font-size: 32px;
}

.font-size-40 {
    font-size: 40px;
}

/*----------------------------------------------
Avatar Size
------------------------------------------------*/
.avatar-35 {
    height: 35px;
    width: 35px;
    line-height: 35px;
    font-size: 0.5rem;
}

.avatar-30 {
    height: 30px;
    width: 30px;
    line-height: 30px;
    font-size: 0.4rem;
}

.avatar-40 {
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 0.6rem;
}

.avatar-45 {
    height: 45px;
    width: 45px;
    line-height: 45px;
    font-size: 0.8rem;
}

.avatar-50 {
    height: 50px;
    width: 50px;
    line-height: 50px;
    font-size: 1rem;
}

.avatar-60 {
    height: 60px;
    width: 60px;
    line-height: 60px;
    font-size: 1.2rem;
}

.avatar-70 {
    height: 70px;
    width: 70px;
    line-height: 70px;
    font-size: 1.4rem;
}

.avatar-80 {
    height: 80px;
    width: 80px;
    line-height: 80px;
    font-size: 1.6rem;
}

.avatar-90 {
    height: 90px;
    width: 90px;
    line-height: 90px;
    font-size: 1.6rem;
}

.avatar-100 {
    height: 100px;
    width: 100px;
    line-height: 100px;
    font-size: 1.6rem;
}

.avatar-110 {
    height: 110px;
    width: 110px;
    line-height: 110px;
    font-size: 1.6rem;
}

.avatar-120 {
    height: 120px;
    width: 120px;
    line-height: 120px;
    font-size: 1.6rem;
}

.avatar-130 {
    height: 130px;
    width: 130px;
    line-height: 130px;
    font-size: 1.6rem;
}

/*----------------------------------------------
Margin Bottom
------------------------------------------------*/
.iq-mb-3 {
    margin-bottom: 30px !important;
}

/*----------------------------------------------
Card
------------------------------------------------*/
.iq-card {
    background: #ffffff;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    margin-bottom: 30px;
    border: none;
    -webkit-box-shadow: 0px 4px 5px 1px rgba(175, 175, 175, 0.1);
    box-shadow: 0px 4px 5px 1px rgba(175, 175, 175, 0.1);
}

.iq-card .iq-card {
    box-shadow: none;
}

.iq-card .iq-card .iq-card-header {
    border: none;
}

.iq-card-body {
    padding: 20px;
}

.iq-card .iq-card-header {
    padding: 0 20px;
    min-height: 60px;
    -ms-flex-align: center !important;
    align-items: center !important;
    border-bottom: 1px solid #eeeeee;
    border-radius: 25px 25px 0 0;
    -webkit-border-radius: 25px 25px 0 0;
}

.iq-card .iq-card-header .iq-header-title {
    display: inline-block;
    align-self: center !important;
}

.iq-card .iq-card-header .iq-header-title .card-title {
    margin-bottom: 0;
}

.iq-card-header-toolbar .nav-item a {
    color: #393c52;
    padding: 4px 12px;
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
}

.iq-card-header-toolbar .dropdown-toggle i {
    font-size: 22px;
    line-height: normal;
    color: #393c52;
}

.iq-card-header-toolbar .dropdown-toggle::after {
    display: none;
}




.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    margin-left: 10px !important;

    border: 1px solid transparent;
}

.slide-item {
    margin-right: 10px !important; /* Adjust this value to control the spacing between slides */
  }

  .top_100_description{transform: rotate(45deg);margin-left: -3px;transition: all ease-in-out .2s;-webkit-transition: all ease-in-out .2s;}


  /* Search Modal Data on key search */
  .modal.searchModel .ui-autocomplete {
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: 165px;
    max-width: 100%;
    overflow: auto;
    max-height: 140px;
    padding: 0 20px;
  }

  body .ui-widget.ui-widget-content {
    border: 1px solid #dcdbde;
    border-top: 0px;
  }

  .ui-autocomplete {
    width: 800px;
    max-width: 100%;
    padding: 20px;
    text-align: center;
    background: transparent;
    margin: auto;
  }
  .compareInputPanel .ui-autocomplete {
    width: 100% !important;
    max-width: 90%;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 0 0 10px 10px;
    text-align: left;
    background: #fafafa;
    position: absolute;
    z-index: 2;
  }

  .compareInputPanel .ui-autocomplete li a {
    color: #000;
  }
  .ui-autocomplete li {
    list-style-type: none;
    line-height: 36px;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: rgba(255, 255, 255, .1);
  }
  .ui-autocomplete li a {
    color: #fff;
    font-size: 16px
  }
  .ui-autocomplete li a {
    cursor: pointer !important;
  }

  .start_label{
    color: red !important;
  }

  /*  */

  .searchSectionBox .bgWhiteBox .compareInputPanel .dropdown {
    position: absolute;
    display: inline-block;
    right: 8px;
    top: 10px;
    color: rgb(176, 190, 197);
    z-index: 9;
  }
  .searchSectionBox .bgWhiteBox .compareInputPanel .dropdown > i {
    width: 32px;
    height: 32px;
    background: #3d424c;
    text-align: center;
    border-radius: 50%;
    margin-right: 4px;
    color: #9c9fa5;
    cursor: pointer;
    line-height: 32px;
    font-size: 19px;
  }
  .searchSectionBox .bgWhiteBox .compareInputPanel .dropdown > i:hover {
    background: #3f3d56;
  }
  .searchSectionBox .bgWhiteBox .compareInputPanel .dropdown .dropdown-menu {
    padding: 0;
    min-width: 7rem;
    right: 0;
    left: unset !important;
  }
  .searchSectionBox .bgWhiteBox .compareInputPanel .dropdown .dropdown-menu .dropdown-item {
    padding: 8px 10px;
    font-size: 13px;
    line-height: 15px;
    color: rgb(69, 90, 100);
  }
  .searchSectionBox .bgWhiteBox .compareInputPanel .dropdown .dropdown-menu .dropdown-item i {
    font-size: 13px;
    color: rgb(69, 90, 100);
    margin-right: 5px;
  }
  .searchSectionBox .bgWhiteBox .compareInputPanel .dropdown .dropdown-menu .dropdown-item:hover {
    background: #fc9a3a;
    color: #fff;
  }
  .searchSectionBox .bgWhiteBox .compareInputPanel .dropdown .dropdown-menu .dropdown-item:hover i {
    color: #fff;
  }